import React from 'react'
import Layout from '../components/layout.js'
import Seo from '../components/seo.js'
import ProfessionalContent from './partials/professioanalcontent.js'

import msGlass from '../images/mag-str.png'
import './professional.css.less'
import bgImg from '../images/prof-cptable.png'
import contentImg from '../images/profContent.png'

const Professional = () => {
	return (
		<Layout>
			<Seo title="professional" />
			<header className="v-header prof-container">
				<div className="prof-image-wrap">
					<img
						src={bgImg}
						alt=""
						className="prof-image"
						style={{ backgroundSize: 'contain' }}
					/>
					<img src={msGlass} alt="" className="prof-msGlass-r" />
				</div>
				<div
					className="prof-header-content"
					style={{
						backgroundImage: `url(${contentImg})`,
						backgroundSize: 'cover'
					}}
				>
					<div className="prof-header-title">
						<h5>PROFESSIONAL</h5>
					</div>
				</div>
			</header>
			<ProfessionalContent />
		</Layout>
	)
}

export default Professional
